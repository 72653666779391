body,
html,
#root,
main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

body {
  background-color: #ffffff;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-clear-button {
  -webkit-appearance: none;
  margin: 0;
}

table {
  width: 100%;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.read-only {
  font-weight: 600;
}

.shapediver-domElements {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
}

.shapediver-domElement {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  pointer-events: none;
  position: absolute;
  font-family: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  white-space: nowrap;
  text-overflow: clip;
}

.shapediver-domElement-span {
  display: block;
  text-overflow: clip;
  overflow: hidden;
}

.dimensions::-webkit-inner-spin-button,
.dimensions::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.dimensions {
    -moz-appearance: textfield !important;
    background-color: #fff;
    overflow: hidden;
}

.dimensions:after {
  content: '.' attr(placeholder);
  position: absolute;
  top: 4px;
  left: 94px;
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  display: block;
  font-weight: bold;
}

input:focus,
textarea {
  outline: none !important;
}
